import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';

const FavoriteStar = ({ onStarClick, personUid, favoriteIds }) => {
    const [isFavorited, setIsFavorited] = useState(false);

    useEffect(() => {
        setIsFavorited(favoriteIds?.includes(personUid));
    }, [favoriteIds, personUid]);

    const handleClick = () => {
        onStarClick(personUid);
        setIsFavorited(!isFavorited);
    };

    const Icon = isFavorited ? Star : StarBorderOutlinedIcon;

    return (
        <IconButton edge="end" aria-label="favorite" onClick={handleClick}>
            <Icon />
        </IconButton>
    );
};

export default FavoriteStar;
