import React from "react";
import * as dateFns from "date-fns";
import DayData from "./DayData";

const CalendarBody = ({ isMobile, startDate, endDate, currentMonth, selectedDate, onDateClick, calData }) => {
  const rowCount = isMobile ? 1 : 7;
  let days = [];
  let day = startDate;

  while (day <= endDate) {
    for (let i = 0; i < rowCount; i++) {
      const cloneDay = day;
      days.push({
        day: cloneDay,
        dayData: calData[cloneDay.getDate()],
      });
      day = dateFns.addDays(day, 1);
    }
  }

  const dateFormat = "d";

  return (
    <div className={isMobile ? "row mobile" : "row"}>
      {days.map((dayInfo) => {
        const today = dayInfo.day;
        const formattedDate = dateFns.format(today, dateFormat);
        const isDisabled = !dateFns.isSameMonth(today, dateFns.startOfMonth(currentMonth));
        const isSelected = dateFns.isSameDay(today, selectedDate);

        return (
          <div
            className={`col cell ${isDisabled ? "disabled" : isSelected ? "selected" : ""}`}
            key={today}
            onClick={() => onDateClick(today)}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
            <DayData dateToMap={today} dataToMap={dayInfo.dayData} />
          </div>
        );
      })}
    </div>
  );
};

export default CalendarBody;
