// AppBarComponent.js
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Grid, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SignOut from "./SignOut";
import { dynamicData } from "../config/InTheOfficeApi";

function AppBarComponent({ user, toggleDrawer }) {
  return (
    <AppBar
      color="default"
      elevation={0}
      style={{
        position: "fixed",
        paddingTop: 5,
        paddingBottom: 5,
      }}
      sx={{
        position: "relative",
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        {user ? (
          <>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Button onClick={toggleDrawer}>
                    <MenuIcon />
                  </Button>

                  <Typography variant="h6" color="inherit" noWrap>
                    {dynamicData.company_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <SignOut
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 0,
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
