import axios from 'axios';
import {apiUrl} from "../../config/InTheOfficeApi";
import { SET_COMMUNITY_DATA} from "../../constants";
import {auth} from "../../config/Firebase";

export const setCommunities = (data) => {
    return {
        type: SET_COMMUNITY_DATA,
        payload: data
    }
}

export const setCommunityInDB = (communityToAdd) => async (dispatch, getState) => {
    const syear = communityToAdd.startdate.getFullYear();
    const smonth = communityToAdd.startdate.getMonth()+1;
    const sday = communityToAdd.startdate.getDate()+1;
    const sfixedMonth = smonth.toString().length === 1 ? `0${smonth}`: smonth;
    const sfixedDay = sday.toString().length === 1 ? `0${sday}`: sday;

    const eyear = communityToAdd.enddate.getFullYear();
    const emonth = communityToAdd.enddate.getMonth()+1;
    const eday = communityToAdd.enddate.getDate()+1;
    const efixedMonth = emonth.toString().length === 1 ? `0${emonth}`: emonth;
    const efixedDay = eday.toString().length === 1 ? `0${eday}`: eday;

    await axios({
        method: 'post',
        url:`${apiUrl}/community`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: {
            "name": communityToAdd.name,
            "location": `101 Crawfords Corner Rd 3-100, Holmdel, NJ 07733`,
            "description": communityToAdd.description,
            "photoURL": communityToAdd.photoUrl,
            "startdate": `${syear}-${sfixedMonth}-${sfixedDay}`,
            "enddate": `${eyear}-${efixedMonth}-${efixedDay}`
        }
    })
    dispatch(getCommunitiesForMonth(getState().calendarReducer.selectedDate))
}



export const getCommunitiesForMonth = (startDate) => async (dispatch, getState) => {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth()+1;
    const lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0).getDate();

    const request = await axios({
        method: 'post',
        url:`${apiUrl}/community/getByDate`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: {
            "startDate": `${startYear}-${startMonth}-1`,
            "endDate" : `${startYear}-${startMonth}-${lastDayOfMonth}`
        }
    });
    dispatch(setCommunities(request))
}



