import * as dateFns from "date-fns";
import text from "../../../assests/text_en.json";
import {getCalDataForMonth, setSelectedDate} from "../../../redux/actions/CalendarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getEventsForMonth} from "../../../redux/actions/EventActions";
import {getCommunitiesForMonth} from "../../../redux/actions/CommunityActions";
import React, { useState, useEffect } from "react";

const Header = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleWindowResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };
    
      useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
      }, []);

    const dateFormat = isMobile ? "EEEE, MMMM d, yyyy" : "MMMM yyyy";
    const days = [];
    const {selectedDate} = props;

    const getDays = (i) => {
        switch (i) {
            case 1:
                return text.monday
            case 2:
                return text.tuesday
            case 3:
                return text.wednesday
            case 4:
                return text.thursday
            case 5:
                return text.friday
            case 6:
                return ""
            default:
                return ""
        }
    }

    for (let i = 0; i < 7; i++) {
        days.push(
            <div className="col col-center" key={i}>
                {getDays(i)}
            </div>
        );
    }

    const changeDay = (increment) => {
        const newDate = new Date(
          selectedDate.setDate(selectedDate.getDate() + increment)
        );
        callDataForMonth(newDate);
      };
      
      const nextDay = () => {
        if (isMobile) {
          changeDay(1);
        } else {
          const newDate = new Date(
            selectedDate.setMonth(selectedDate.getMonth() + 1)
          );
          callDataForMonth(newDate);
        }
      };
      
      const prevDay = () => {
        if (isMobile) {
          changeDay(-1);
        } else {
          const newDate = new Date(
            selectedDate.setMonth(selectedDate.getMonth() - 1)
          );
          callDataForMonth(newDate);
        }
      };
      

    function callDataForMonth(newDate) {
        props.actions.getCalDataForMonth(newDate);
        props.actions.getEventsForMonth(selectedDate);
        props.actions.getCommunitiesForMonth(selectedDate);
        props.actions.setSelectedDate(newDate);
    }   

    return (
        <>
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={prevDay}>
                        chevron_left
                    </div>
                </div>
                <div className="col col-center">
                    <span>{dateFns.format(selectedDate, dateFormat)}</span>
                </div>
                <div className="col col-end" onClick={nextDay}>
                    <div className="icon">chevron_right</div>
                </div>
            </div>
            {!isMobile && <div className="days row">{days}</div>}
           
        </>

    );
}

const mapStateToProps = (state) => ({
    selectedDate: state.calendarReducer.selectedDate
})

const ActionCreators = Object.assign(
    {},
    { setSelectedDate },
    {getCalDataForMonth},
    {getEventsForMonth},
    {getCommunitiesForMonth}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);