import {SET_APP_ERROR, TOGGLE_DIALOG} from "../../constants";

export const toggleDialog = (data) => {
    return {
        type: TOGGLE_DIALOG,
        payload: data
    }
}



export const setError = (data) => {
    return {
        type: SET_APP_ERROR,
        payload: data
    }
}
