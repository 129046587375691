import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import text from "../assests/text_en.json"
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import {setEventInDB, setEvents} from "../redux/actions/EventActions";
import ConfirmationMessage from "./ConfirmationMessage";

const EventForm = (props) => {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [submitted, setSubmitted] = React.useState(false);


    const changeStart = (newValue) => {
        setStartDate(newValue);
    };

    const changeEnd = (newValue) => {
        setEndDate(newValue);
    };

    const sendFormData = (e) => {
        e.preventDefault();
        const eventTitle = e?.target?.elements?.eventTitle?.value;
        const eventImage = e?.target?.elements?.eventImage?.value;
        const eventDetails = e?.target?.elements?.eventDetails?.value;
        const eventData = {
            "name": eventTitle,
            "photoUrl":eventImage,
            "description": eventDetails,
            "startdate": startDate,
            "enddate": endDate
        }
        props.actions.setEventInDB(eventData)
        setSubmitted(true)
    }

    return (
        <>
            {
                submitted ?
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <ConfirmationMessage text={text.thank_you_for_the_submission}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => setSubmitted(false)} variant="contained">{text.submit_another_event}</Button>
                        </Grid>
                    </Grid>
                    :
                    (
                        <form onSubmit={sendFormData}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Typography variant="h6" gutterBottom>
                                    {text.event_details}
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="eventTitle"
                                            name="eventTitle"
                                            label="Event Title"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="eventImage"
                                            name="eventImage"
                                            label="Event Image Url"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            required

                                            label="Start Time"
                                            value={startDate}
                                            onChange={changeStart}
                                            renderInput={(params) => <TextField id="eventStart" name="eventStart" {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            required
                                            id="eventEnd"
                                            name="eventEnd"
                                            label="End Time"
                                            value={endDate}
                                            onChange={changeEnd}
                                            renderInput={(params) => <TextField id="eventStart" name="eventStart" {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="eventDetails"
                                            name="eventDetails"
                                            label="Event Details"
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12}  justifyContent="flex-end">
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{ mt: 3, ml: 1 }}
                                        >
                                            {text.submit_event}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </form>
                    )
            }
        </>
    );
}

const mapStateToProps = state => ({

});

const ActionCreators = Object.assign(
    {},
    { setEvents },
    {setEventInDB}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);