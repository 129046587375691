import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
let useTest = false;

const testConfig = {
    apiKey: "AIzaSyC1vZEjglY9ZppTk4GcC_ndr8dpaIq1vkw",
    authDomain: "intheoffficetest.firebaseapp.com",
    projectId: "intheoffficetest",
    storageBucket: "intheoffficetest.appspot.com",
    messagingSenderId: "366028803921",
    appId: "1:366028803921:web:7e22527a78ea131af18d70",
    measurementId: "G-0YMFPLQH6J"
}

const prodConfig =  {
    apiKey: "AIzaSyBCOKibkXPOoro6Vo8OWejn36G5JnKgO7g",
    authDomain: "intheoffice-838c5.firebaseapp.com",
    projectId: "intheoffice-838c5",
    storageBucket: "intheoffice-838c5.appspot.com",
    messagingSenderId: "511784583458",
    appId: "1:511784583458:web:3c90e0d9f0cd9fe107a57b",
    measurementId: "G-P0RGMG17XW"
}


firebase.initializeApp(useTest ? testConfig : prodConfig)

export const auth = firebase.auth();
export const db = firebase.firestore();
export const analytics = firebase.analytics();

export default firebase;