import {SET_CAL_DATA, SET_SELECTED_DATE} from "../../constants";

const initialState =  {
    calData: {},
    selectedDate: new Date()
};

const CalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CAL_DATA:
            const calMap = {}
            if(action && action.payload && action.payload.data){
                action.payload.data.forEach((d) =>{
                    const day = new Date(d.date).getDate();
                    if(calMap[day]){
                        calMap[day].push(d)
                    } else {
                        calMap[day] = [];
                        calMap[day].push(d);
                    }
                })
            }
            return {
                ...state,
                calData: calMap
            }
        case SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload
            }
        default:
            return state
    }
}

export default CalendarReducer
