import axios from 'axios';
import {apiUrl} from "../../config/InTheOfficeApi";
import {SET_USER_DATA, SET_USER_FAVORITES} from "../../constants";
import {auth} from "../../config/Firebase";

export const setUserData = (data) => {
    return {
        type: SET_USER_DATA,
        payload: data
    }
}

export const setUserFavorites = (data) => {
    return {
        type: SET_USER_FAVORITES,
        payload: data
    }
}

export const getUserDataInDB = (userId) => async (dispatch, getState) => {
    const result = await axios({
        method: 'get',
        url: `${apiUrl}/people/${userId}`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        }
    });
    dispatch(setUserData(result.data))
}


export const setUserDataInDB = (data) => async (dispatch, getState) => {
    await axios({
        method: 'post',
        url:`${apiUrl}/people`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: data

    });
    dispatch(setUserData(data))
}

export const setUserFavoritesInDB = (data) => async (dispatch, getState) => {
    await axios({
        method: 'post',
        url:`${apiUrl}/people/addFavorite`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: data

    });

    dispatch(setUserFavorites(data))
}

export const removeUserFavoritesInDB = (data) => async (dispatch, getState) => {
    await axios({
        method: 'post',
        url:`${apiUrl}/people/deleteFavorite`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: data

    });
    dispatch(setUserFavorites(data))
}



