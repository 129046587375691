import React, {useEffect, useState} from 'react';
import { Button, Grid} from "@material-ui/core";
import text from '../assests/text_en.json';
import Community from "../components/Community";
import Events from "../components/Events";
import RecurringScheduler from "../components/RecurringScheduler";
import Calendar from "../components/Calendar/Calendar";
import { connect } from 'react-redux';
import {getCalDataForMonth} from '../redux/actions/CalendarActions';
import { bindActionCreators } from 'redux';
import {getEventsForMonth} from '../redux/actions/EventActions';
import {getCommunitiesForMonth} from '../redux/actions/CommunityActions';

const HomePage = (props) => {
    const appError = props.appError;
    //create a variable called reoccur that uses the state to trigger a ui element when click
    const [reoccur, setReoccur] = useState(false);
    useEffect(() => {
        const selectedDate = props.selectedDate;
        props.actions.getCalDataForMonth(selectedDate);
        props.actions.getEventsForMonth(selectedDate);
        props.actions.getCommunitiesForMonth(selectedDate);
    }, [props.selectedDate, props.actions]);

    return  (
            <div className="App">
                    <h1>{text.greeting}</h1>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        {reoccur ? 
                            <RecurringScheduler/> 
                                : 
                            <Button variant="contained" color="primary" onClick={() => setReoccur(true)}>{text.recurring_scheduler}</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Calendar/>
                    </Grid>
                    <Community/>
                    <Events/>
                </Grid>
            </div>

    );
};


const mapStateToProps = state => ({
    selectedDate: state.calendarReducer.selectedDate,
    appError: state.applicationReducer.error
});

const ActionCreators = Object.assign(
    {},
    { getCalDataForMonth },
    { getEventsForMonth },
    { getCommunitiesForMonth }
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);