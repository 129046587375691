import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import text from "../assests/text_en.json"
import {setEvents} from "../redux/actions/EventActions";
import CommunityForm from "../components/CommunityForm";


const CommunityPage = () => {

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    {text.create_community}
                </Typography>
                <CommunityForm />
            </Paper>
        </Container>
    );
}

const mapStateToProps = state => ({
    selectedDate: state.calendarReducer.selectedDate
});

const ActionCreators = Object.assign(
    {},
    {setEvents}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityPage);