import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {CardHeader} from "@material-ui/core";


const useStyles = makeStyles({
    media: {
        height: 140,
    },
});

const MediaCard = (props) => {
    const classes = useStyles();
    const {img, desc, title, dateRange} = props;
    return (
        <Card className={classes.root}>
            <CardHeader
                title={title}
                subheader={dateRange}
            />

                {img ?
                    <CardMedia
                        className={classes.media}
                        image={img}
                        title="Image"
                    />
                    : null}

                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {desc}
                    </Typography>
                </CardContent>
        </Card>
    );
}
export default MediaCard;