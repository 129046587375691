import { SET_COMMUNITY_DATA } from "../../constants";
import * as dateFns from "date-fns";

const initialState =  {
    communityData: [],
    communityMap: {}
};

function getDateCompare(startDateCopy) {
    return parseInt(`${startDateCopy.getFullYear()}${startDateCopy.getMonth()}${startDateCopy.getDate()}`);
}

const CalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMMUNITY_DATA:
            const communityData = {}
            if(action && action.payload && action.payload.data){
                action.payload.data.forEach((d) =>{
                    let startDateCopy = new Date(d.startdate);
                    while(getDateCompare(startDateCopy) <= getDateCompare(new Date(d.enddate))){
                        if(startDateCopy.getMonth() === new Date(d.startdate).getMonth()){
                            communityData[startDateCopy.getDate()] = startDateCopy.getMonth()
                        }
                        startDateCopy =  dateFns.addDays(startDateCopy, 1)
                    }
                })
            }
            return {
                ...state,
                communityData: action.payload.data,
                communityMap: communityData
            }
        default:
            return state
    }
}

export default CalendarReducer
