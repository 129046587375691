import axios from 'axios';
import {apiUrl} from "../../config/InTheOfficeApi";
import {auth} from "../../config/Firebase";
import {SET_CAL_DATA, SET_SELECTED_DATE} from "../../constants";
import {setError} from "./ApplicationActions";

function formatDateForDB(date, correctForZero) {
    const year = date.getFullYear();
    const month = correctForZero ? date.getMonth() + 1 : date.getMonth();
    const day = correctForZero ? date.getDate() + 1 : date.getDate();
    const fixedMonth = month.toString().length === 1 ? `0${month}` : month;
    const fixedDay = day.toString().length === 1 ? `0${day}` : day;
    return `${year}-${fixedMonth}-${fixedDay}`;
}

function formatDateForDBFromTimeStamp(date, correctForZero) {
    const year = date.getFullYear();
    const month = correctForZero ? date.getMonth() + 1 : date.getMonth();
    const day = correctForZero ? date.getDate() + 1 : date.getDate();
    const fixedMonth = month.toString().length === 1 ? `0${month}` : month;
    const fixedDay = day.toString().length === 1 ? `0${day}` : day;
    return `${year}-${fixedMonth}-${fixedDay}`;
}

async function makeAddToCalendarRequest(dateArray) {
    await axios({
        method: 'post',
        url: `${apiUrl}/calendar`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: {
            "attendance": dateArray
        }
    })
}


export const setCalData = (data) => {
    return {
        type: SET_CAL_DATA,
        payload: data
    }
}

export const setSelectedDate = (date) => {
    return {
        type: SET_SELECTED_DATE,
        payload: date
    }
}

export const getCalDataForMonth = (startDate) => async (dispatch, getState) => {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth()+1;
    const lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0).getDate();

    const request = await axios({
        method: 'post',
        url:`${apiUrl}/calendar/getByDate`,
        headers: {
            Authorization: await auth?.currentUser?.getIdToken(/* forceRefresh */ true)
        },
        data: {
            "startDate": `${startYear}-${startMonth}-1`,
            "endDate" : `${startYear}-${startMonth}-${lastDayOfMonth}`
        }
    });

    if(request.status === 200){
        dispatch(setCalData(request))
    } else {
        dispatch(setError(true))
    }

}

export const setReccurringSchedule = (dates, personUid) => async (dispatch, getState) => {
    const dateArray =[];
    dates.forEach(timestamp => {
        const arrayInfo = {
            "attendingPersonId": personUid,
            "date": formatDateForDB(timestamp._date, true)
        }
        dateArray.push(arrayInfo)
    });
    await makeAddToCalendarRequest(dateArray);
    dispatch(getCalDataForMonth(getState().calendarReducer.selectedDate))
}

export const setAttendanceDate = (dateToAdd, personUid) => async (dispatch, getState) => {
    const fixedDate = formatDateForDB(dateToAdd, true);
    const dateArray = [
        {
            "attendingPersonId": personUid,
            "date": fixedDate
        }
    ]
    await makeAddToCalendarRequest(dateArray);
    dispatch(getCalDataForMonth(getState().calendarReducer.selectedDate))
}

export const removeAttendanceDate = (dateToAdd, personUid) => async (dispatch, getState) => {
    const fixedDate = formatDateForDB(dateToAdd, true);

    const dateArray = [
        {
            "attendingPersonId": personUid,
            "date": fixedDate
        }
    ]
    await axios({
        method: 'post',
        url:`${apiUrl}/removeAttendance`,
        headers: {
            Authorization: await auth.currentUser.getIdToken(/* forceRefresh */ true)
        },
        data: {
            "attendance": dateArray
        }
    })
    dispatch(getCalDataForMonth(getState().calendarReducer.selectedDate))
}
