import React from "react";
import './calendar.css'
import CalendarCells from "./components/CalendarCells";
import {connect} from "react-redux";
import {setSelectedDate, getCalDataForMonth} from "../../redux/actions/CalendarActions";
import {bindActionCreators} from "redux";
import Header from "./components/Header";

const Calendar = (props) => {
    const {selectedDate} = props;
    return (
            <div className="calendar">
                <Header/>
                <CalendarCells currentMonth={selectedDate}/>
            </div>
        );
}


const mapStateToProps = (state) => ({
    calData: state.calendarReducer.calData,
    selectedDate: state.calendarReducer.selectedDate
})

const ActionCreators = Object.assign(
    {},
    { setSelectedDate },
    {getCalDataForMonth}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);