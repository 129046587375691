import React, { useState } from "react";
import { auth } from "../config/Firebase";
import {
    Checkbox,
    FormControl, FormControlLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import getDates from '../utils/scheduleMaker';
import text from '../assests/text_en.json';
import {setReccurringSchedule} from "../redux/actions/CalendarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useAuthState} from "react-firebase-hooks/auth";
import Grid from "@mui/material/Grid";
import ConfirmationMessage from "./ConfirmationMessage";

const RecurringScheduler = (props) => {
    let now = new Date();
    const [user] = useAuthState(auth);
    //Possible day values:
    //["SU","MO","TU","WE","TH","FR","SA"]
    const reccuringFrequencies = {
        weekly: 'WEEKLY',
        monthly: 'MONTHLY',
        yearly: 'YEARLY'
    }
    const daysOfTheWeek = {
        mon: "MO",
        tues: "TU",
        wed: "WE",
        thurs: "TH",
        fr: "FR"
    }

    const [frequency, setFrequency] = useState(reccuringFrequencies.weekly);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [recurringStart, setRecurringStart] = useState(new Date());
    const [recurringEnd, setRecurringEnd] = useState(now.setDate(now.getDate() + 4 * 7));
    const [monday, setMonday] = React.useState(false);
    const [tuesday, setTuesday] = React.useState(false);
    const [wednesday, setWednesday] = React.useState(false);
    const [thursday, setThursday] = React.useState(false);
    const [friday, setFriday] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);


    const updateDaysOfWeek = (day) => {
        const copyArr = daysOfWeek;
        if (daysOfWeek.includes(day)) {
            copyArr.splice(daysOfWeek.indexOf(day), 1)
            setDaysOfWeek(copyArr);
        } else {
            copyArr.push(day);
            setDaysOfWeek(copyArr);
        }
    }

    const sendRecurringDays = (e) => {
        e.preventDefault();
        if(daysOfWeek?.length !== 0) {
            const userRecurringSchedule = getDates(frequency, daysOfWeek, recurringStart, recurringEnd);
            const timestamps = userRecurringSchedule.occurrences().toArray();
            props.actions.setReccurringSchedule(timestamps, user.uid);
            setMonday(false)
            setTuesday(false)
            setWednesday(false)
            setThursday(false)
            setFriday(false)
            setSubmitted(true)
        }
    }

    return  submitted ?
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <ConfirmationMessage text={text.thank_you_for_the_submission}/>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() => setSubmitted(false)} variant="contained">{text.submit_more_dates}</Button>
            </Grid>
        </Grid>
        : (
        <form onSubmit={sendRecurringDays}>
                <FormControl >
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                    >
                        <MenuItem value="WEEKLY">{text.weekly}</MenuItem>
                        <MenuItem value="MONTHLY">{text.monthly}</MenuItem>
                        <MenuItem value="YEARLY">{text.yearly}</MenuItem>
                    </Select>

                    <FormControlLabel
                        control={<Checkbox checked={monday} onChange={() => {
                            updateDaysOfWeek(daysOfTheWeek.mon)
                            setMonday(!monday)
                        }} name="mon" />}
                        label={text.monday}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={tuesday} onChange={() => {
                            updateDaysOfWeek(daysOfTheWeek.tues)
                            setTuesday(!tuesday)
                        }} name="tues" />}
                        label={text.tuesday}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={wednesday} onChange={() => {
                            updateDaysOfWeek(daysOfTheWeek.wed)
                            setWednesday(!wednesday)
                        }} name="wed" />}
                        label={text.wednesday}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={thursday} onChange={() => {
                            updateDaysOfWeek(daysOfTheWeek.thurs)
                            setThursday(!thursday)
                        }} name="thurs" />}
                        label={text.thursday}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={friday} onChange={() => {
                            updateDaysOfWeek(daysOfTheWeek.fr)
                            setFriday(!friday)
                        }} name="fri" />}
                        label={text.friday}
                    />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>


                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Choose recurring start date"
                                    value={recurringStart}
                                    onChange={(date) => setRecurringStart(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />

                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Choose recurring end date"
                                    value={recurringEnd}
                                    onChange={(date) => setRecurringEnd(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                    <Button color="primary" variant="contained" type="submit" >{text.set_schedule}</Button>
                </FormControl>
        </form>
    )
}


const mapStateToProps = state => ({

});

const ActionCreators = Object.assign(
    {},
    { setReccurringSchedule },
    {  },
    {  }
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurringScheduler);