import React, {useEffect, useMemo} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";
import {Avatar, Typography} from "@material-ui/core";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../config/Firebase";
import {getUserDataInDB} from "../redux/actions/PersonActions";
import SignOut from "../components/SignOut";
import FavoritesList from "../components/FavoritesList";

const ProfilePage = (props) => {
    const [user] = useAuthState(auth);
    const {personData} = useMemo(() => props, [props])
    useEffect(() =>{
        props.actions.getUserDataInDB(user.uid);
    }, [user.uid,props.actions])

    return  (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Avatar alt={`${personData?.firstName} ${personData?.lastName}`} src={personData?.photoURL} style={{ width: 200, height: 200 }}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom component="div">
                    {personData?.firstName} {personData?.lastName}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom component="div">
                    {personData?.email}
                </Typography>

            </Grid>
            <Grid item xs={12}>
                <FavoritesList />
            </Grid>
            <Grid item xs={12}>
                <SignOut/>
            </Grid>

        </Grid>
    );
};

const mapStateToProps = state => ({
    personData: state.personReducer.personData.person
});

const ActionCreators = Object.assign(
    {},
    {getUserDataInDB}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);