import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";

const ConfirmationMessage = (props) => {
    const {text} = props
    return (
      <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
      >
          <Grid item xs={12}>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
          </Grid>
          <Grid item xs={12}>
              {text}
          </Grid>
      </Grid>
    )

}

const mapStateToProps = (state) => ({
    events: state.eventsReducer.eventDays,
    communities: state.communityReducer.communityMap
})

const ActionCreators = Object.assign(
    {},
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationMessage);