import React from 'react';
import {Grid} from "@material-ui/core";
import MediaCard from "./MediaCard";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import text from "../assests/text_en.json";

const Events = (props) => {
    const {communities} = props;

    const communityJSX =  (
        <Grid item xs={6}>
        <h2>{text.communities}</h2>
        <Grid container spacing={3}>
            {communities.map((c) => {
                return (
                    <Grid item lg={6} md={6} xs={12}>
                        <MediaCard img={c.photoURL} desc={c.description} title={c.name} />
                    </Grid>)
            })}
        </Grid>
    </Grid>)

    return  ( communities.length === 0 ?  null : communityJSX);
};

const mapStateToProps = (state) => ({
    communities: state.communityReducer.communityData
})

const ActionCreators = Object.assign(
    {},
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);