import { SET_EVENT_DATA} from "../../constants";
import * as dateFns from "date-fns";


const initialState =  {
    eventData: [],
    eventMap: {}
};

function getDateCompare(startDateCopy) {
    return parseInt(`${startDateCopy.getFullYear()}${startDateCopy.getMonth()}${startDateCopy.getDate()}`);
}

const EventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EVENT_DATA:
            const eventMap = {}
            if(action && action.payload && action.payload.data){
                action.payload.data.forEach((d) =>{
                    let startDateCopy = new Date(d.startdate);
                    while(getDateCompare(startDateCopy) <= getDateCompare(new Date(d.enddate))){
                        if(startDateCopy.getMonth() === new Date(d.startdate).getMonth()){
                            eventMap[startDateCopy.getDate()] = startDateCopy.getMonth()
                        }
                        startDateCopy =  dateFns.addDays(startDateCopy, 1)
                    }
                })
            }
            return {
                ...state,
                eventData: action.payload.data,
                eventDays: eventMap
            }
        default:
            return state
    }
}

export default EventsReducer
