import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import reducer from './redux/reducers'
import thunk from 'redux-thunk';
import {ThemeProvider} from "@mui/material";
import { createTheme } from '@mui/material/styles';

const store = createStore(reducer, applyMiddleware(thunk));

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <Provider store={store}>
              <BrowserRouter  basename="/">
                  <App />
              </BrowserRouter>
          </Provider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
