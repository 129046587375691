// DrawerComponent.js
import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GroupIcon from "@material-ui/icons/Group";
import EventIcon from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { links } from "../constants";
import ListItemLink from "./ListItemLink";

function DrawerComponent({ anchor, open, onClose, signOutFunc, isAdmin }) {
  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <div role="presentation" onClick={onClose} onKeyDown={onClose}>
        <List>
          <ListItemLink
            to={links.profile}
            primary="Profile"
            icon={<AccountCircleIcon />}
          />
          <Divider />
          <ListItemLink
            to={links.home}
            primary="Home Page"
            icon={<HomeIcon />}
          />
          <ListItemLink
            to={links.events}
            primary="Events"
            icon={<EventIcon />}
          />
          <ListItemLink
            to={links.communities}
            primary="Community"
            icon={<GroupIcon />}
          />
          {isAdmin ? <Divider /> : <></>}
          {isAdmin ? (
            <ListItemLink
              to={links.admin}
              primary="Admin"
              icon={<AdminPanelSettingsIcon />}
            />
          ) : (
            <></>
          )}
          <Divider />
          <ListItemLink
            to={links.signout}
            onClick={signOutFunc}
            primary="Sign Out"
            icon={<ExitToAppIcon />}
          />
        </List>
      </div>
    </Drawer>
  );
}

export default DrawerComponent;
