import React, { useState, useEffect } from "react";
import * as dateFns from "date-fns";
import "../calendar.css";
import { getCalDataForMonth, setSelectedDate } from "../../../redux/actions/CalendarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleDialog } from "../../../redux/actions/ApplicationActions";
import DayInfoDialog from "./DayInfoDialog";
import CalendarBody from "./CalendarBody";

const CalendarCells = (props) => {
  const { currentMonth, selectedDate, dialogStatus, calData } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  // Helper function to get the start and end dates for the calendar
  const getStartAndEndDates = () => {
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = isMobile
      ? dateFns.startOfDay(currentMonth)
      : dateFns.startOfWeek(monthStart);
    const endDate = isMobile
      ? dateFns.addDays(startDate, 2)
      : dateFns.endOfWeek(monthEnd);
    return { startDate, endDate };
  };

  // Handle window resize
  const handleWindowResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Handle date click
  const onDateClick = (day) => {
    props.actions.setSelectedDate(day);
    props.actions.toggleDialog(!dialogStatus);
  };

  const { startDate, endDate } = getStartAndEndDates();

  return (
    <>
      <DayInfoDialog />
      <div className="body">
        <CalendarBody
          isMobile={isMobile}
          startDate={startDate}
          endDate={endDate}
          currentMonth={currentMonth}
          selectedDate={selectedDate}
          onDateClick={onDateClick}
          calData={calData}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  calData: state.calendarReducer.calData,
  selectedDate: state.calendarReducer.selectedDate,
  dialogStatus: state.applicationReducer.showDialog,
});

const ActionCreators = Object.assign(
  {},
  { setSelectedDate },
  { getCalDataForMonth },
  { toggleDialog }
);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCells);
