import { Navigate } from "react-router-dom";

export const AdminRoute = (props) => {
    const {auth, isAdmin} = props;
        if (!auth || !isAdmin) {
        // user is not authenticated
            return <Navigate to="/" />;
        }
    return props.children;
};

