import firebase from 'firebase/compat/app';
import React, { useState } from "react";
import {auth} from "../config/Firebase";
import {Grid, Button, CircularProgress} from "@material-ui/core";
import {GoogleLoginButton, MicrosoftLoginButton} from "react-social-login-buttons";
import { getAuth, signInWithRedirect, OAuthProvider } from "firebase/auth";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setUserDataInDB} from "../redux/actions/PersonActions";
import {dynamicData} from "../config/InTheOfficeApi";
import text from "../assests/text_en.json"

const SignIn = (props) => {
    const [loading, setLoading] = useState(false);

    const signInWithGoogle = () => {
        setLoading(true);
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider)
            .then((result) => {
                // Handle successful sign in
                setLoading(false);
            })
            .catch((error) => {
                // Handle sign in error
                setLoading(false);
            });
    }

    const signInWithMs = () => {
        setLoading(true);
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        auth.signInWithPopup(provider)
            .then((result) => {
                const userData = {
                    firstName: '',
                    lastName: '',
                    photoURL: '',
                    email: '',
                    uid: ''
                }
                if (result?.user.displayName) {
                    userData.firstName = result.user.displayName?.split(' ')[0];
                    userData.lastName = result.user.displayName?.split(' ')[1];
                }
                if (result?.user.photoURL) {
                    userData.photoURL = result.user.photoURL
                }
                if (result?.user.email) {
                    userData.email = result.user.email
                }
                if (result?.user.uid) {
                    userData.uid = result?.user.uid
                }
                setLoading(false);
                props.actions.setUserDataInDB(userData);
            })
            .catch((error) => {
                setLoading(false);
                // Handle error.
            });
    }

    return (
        <Grid
            container
            spacing={7}
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <img width={'50%'} src={dynamicData.company_logo} alt={text.company_logo_alt_text} style={{
                    paddingTop: '100px',
                    display: "block",
                    margin: "auto"
                }} />
            </Grid>
            <Grid item>
                {loading ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CircularProgress />
                        <Button onClick={() => setLoading(false)}>Cancel</Button>
                    </div>
                ) : (
                    <>
                        { dynamicData.sign_in_options.google ? <GoogleLoginButton onClick={signInWithGoogle} /> : null }
                        { dynamicData.sign_in_options.microsoft ? <MicrosoftLoginButton onClick={signInWithMs} /> : null }
                    </>
                )}
            </Grid>
        </Grid>
    )
}


const mapStateToProps = state => ({
    selectedDate: state.calendarReducer.selectedDate
});

const ActionCreators = Object.assign(
    {},
    { setUserDataInDB }
);

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
