import {TOGGLE_DIALOG, GET_DIALOG, SET_APP_ERROR} from "../../constants";

const initialState =  {
    showDialog: false,
    error: false
};

const ApplicationReducer = (state = initialState, action) => {

    switch (action.type) {
        case TOGGLE_DIALOG:
            return {
                ...state,
                showDialog: action.payload
            }
        case GET_DIALOG:
            return {
                ...state
            }
        case SET_APP_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}

export default ApplicationReducer;