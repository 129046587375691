import './App.css';
import React, { useState, useCallback } from 'react';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'react-calendar/dist/Calendar.css';
import { Container } from "@material-ui/core";
import SignIn from "./components/SignIn";
import { Route, Routes } from 'react-router';
import HomePage from "./pages/HomePage";
import { useAuthState } from "react-firebase-hooks/auth";
import EventsPage from "./pages/EventsPage";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProfilePage from "./pages/ProfilePage";
import { links } from "./constants";
import CommunityPage from "./pages/CommunityPage";
import AdminPage from "./pages/AdminPage";
import { ProtectedRoute } from "./pages/Routes/ProtectedRoute";
import { AdminRoute } from "./pages/Routes/AdminRoute";
import { SignedOutRoute } from "./pages/Routes/SignedOutRoute";
import AppBarComponent from './components/AppBarComponent';
import DrawerComponent from './components/DrawerComponent';
import useDrawerState from './hooks/useDrawerState';
import useFetchData from './hooks/useFetchData';
import { auth } from './config/Firebase';
import { getCalDataForMonth } from "./redux/actions/CalendarActions";
import { getUserDataInDB } from "./redux/actions/PersonActions";
import { getEventsForMonth } from "./redux/actions/EventActions"; // import this action
import { getCommunitiesForMonth } from "./redux/actions/CommunityActions"; // import this action


function App(props) {
    const [user] = useAuthState(auth);
    const isAdmin = true; //user ? props.personData.usertype === 0 : false
    const [drawerState, setDrawerState] = useState(false);
    useFetchData(props.actions, props.selectedDate, user);

    const toggleDrawer = useCallback(() => {
        setDrawerState(!drawerState);
    }, [drawerState]);


    return (
        <>
             <AppBarComponent user={user} toggleDrawer={toggleDrawer} />

                <DrawerComponent
                    anchor="left"
                    open={drawerState}
                    onClose={toggleDrawer}
                    user={user}
                    isAdmin={isAdmin}
                />

            <Container maxWidth="lg" style={{ marginTop: 85 }}>
                <Routes>
                    <Route path={links.profile} element={<ProtectedRoute auth={user}><ProfilePage /></ProtectedRoute>} />
                    <Route path={links.events} element={<ProtectedRoute auth={user}><EventsPage /></ProtectedRoute>} />
                    <Route path={links.communities} element={<ProtectedRoute auth={user}><CommunityPage /></ProtectedRoute>} />
                    <Route path={links.home} element={<ProtectedRoute auth={user}><HomePage /></ProtectedRoute>} />
                    <Route path={links.admin} element={<AdminRoute isAdmin={isAdmin} auth={user}><AdminPage /></AdminRoute>} />
                    <Route path={links.signin} element={<SignedOutRoute auth={user}><SignIn /></SignedOutRoute>} />
                </Routes>
            </Container>
        </>
    );
}

const mapStateToProps = state => ({
    selectedDate: state.calendarReducer.selectedDate,
    personData: state.personReducer.personData.person
});

const ActionCreators = Object.assign(
    {},
    { getCalDataForMonth },
    { getUserDataInDB },
    { getEventsForMonth }, // add this action
    { getCommunitiesForMonth } // add this action
);

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
