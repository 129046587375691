export const SET_CAL_DATA = "SET_CAL_DATA";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const GET_DIALOG = "GET_DIALOG";
export const SET_APP_ERROR = "SET_APP_ERROR";
export const SET_EVENT_DATA = "SET_EVENT_DATA";
export const SET_COMMUNITY_DATA = "SET_COMMUNITY_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_FAVORITES = "SET_USER_FAVORITES";


export const links = {
    home: '/',
    communities: '/communities',
    events: '/events',
    signout: '/signout',
    profile: '/profile',
    admin: '/admin',
    signin: '/signin'
}
