import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    Avatar,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    getUserDataInDB,
    removeUserFavoritesInDB,
    setUserFavoritesInDB
} from '../../../redux/actions/PersonActions';
import {
    removeAttendanceDate
} from '../../../redux/actions/CalendarActions';
import {
    toggleDialog
} from '../../../redux/actions/ApplicationActions';
import { auth } from '../../../config/Firebase';
import FavoriteStar from './FavoriteStar';

const Person = (props) => {
    const { personDetails, selectedDate, dialogStatus, favorites } = props;
    const [user] = useAuthState(auth);
    const [favoriteIds, setFavoriteIds] = useState([]);
    const personUid = personDetails?.person?.uid;
    const personFullName = `${personDetails?.person?.firstName} ${personDetails?.person?.lastName}`;
    const personAvatar = personDetails?.person?.photoURL;
    const [isFavorited, setIsFavorited] = useState(false);

    useEffect(() => {
        props.actions.getUserDataInDB(user.uid);
    }, [user.uid, props.actions]);

    useEffect(() => {
        const newUserIds = favorites?.map((fav) => fav.favoritedPersonId) || [];
        setFavoriteIds(newUserIds);
    }, [favorites]);

    const handleStarClick = (personUid) => {
        const isFavorited = favoriteIds.includes(personUid);
        isFavorited ? props.actions.removeUserFavoritesInDB({ personId: user?.uid, favorites: [personUid] }) :
         props.actions.setUserFavoritesInDB({ personId: user?.uid, favorites: [personUid] });
    };

    const handleDelete = () => {
        props.actions.removeAttendanceDate(selectedDate, user.uid);
        props.actions.toggleDialog(!dialogStatus);
    };

    useEffect(() => {
        props.actions.getUserDataInDB(user.uid);
    }, [user.uid, props.actions]);

    useEffect(() => {
        const newUserIds = favorites?.map((fav) => fav.favoritedPersonId) || [];
        setFavoriteIds(newUserIds);
    }, [favorites]);
   
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <List>
                    <ListItem data-uid={personUid}>
                        <ListItemAvatar>
                            <Avatar alt={personFullName} src={personAvatar} />
                        </ListItemAvatar>
                        <ListItemText primary={personFullName} secondary="Team info" />
                        <ListItemSecondaryAction>
                            {personUid === user.uid ? (
                                <IconButton onClick={handleDelete} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            ) : (
                                <FavoriteStar
                                    favoriteIds={favoriteIds}
                                    isFavorited={isFavorited}
                                    onStarClick={() => handleStarClick(personUid)}
                                    personUid={personUid}
                                />
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    calData: state.calendarReducer.calData,
    selectedDate: state.calendarReducer.selectedDate,
    dialogStatus: state.applicationReducer.showDialog,
    favorites : state.personReducer.personData.favorites
})

const ActionCreators = Object.assign(
    {},
    {removeAttendanceDate},
    { toggleDialog },
    {setUserFavoritesInDB},
    {getUserDataInDB},
    {removeUserFavoritesInDB}

);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Person);