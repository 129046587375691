import {List} from "@material-ui/core";
import React from 'react';
import Person from "./Person";


const PeopleList = (props) => {
    const { peopleInfo, selectedDate } = props;
     // ... other existing code
   
 
    return (
        <List>
            {peopleInfo.map((dayInfo, index) => {
                    return (
                        new Date(dayInfo.date).getDate().toString() === selectedDate.getDate().toString() ? <Person personDetails={dayInfo} key={index} /> : null
                    )
                }
            )}
        </List>
    )
}

export default PeopleList;