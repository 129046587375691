import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import text from "../assests/text_en.json"
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import {setCommunities, setCommunityInDB} from "../redux/actions/CommunityActions";
import ConfirmationMessage from "./ConfirmationMessage";

const CommunityForm = (props) => {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [submitted, setSubmitted] = React.useState(false);

    const changeStart = (newValue) => {
        setStartDate(newValue);
    };

    const changeEnd = (newValue) => {
        setEndDate(newValue);
    };

    const sendFormData = (e) => {
        e.preventDefault();
        const CommunityTitle = e?.target?.elements?.communityTitle?.value;
        const communityImage = e?.target?.elements?.communityImage?.value;
        const CommunityDetails = e?.target?.elements?.communityDetails?.value;
        const CommunityData = {
            "name": CommunityTitle,
            "description": CommunityDetails,
            "photoUrl":communityImage,
            "startdate": startDate,
            "enddate": endDate
        }
        props.actions.setCommunityInDB(CommunityData)
        setSubmitted(true)
    }


    return   submitted ?
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <ConfirmationMessage text={text.thank_you_for_the_submission}/>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() => setSubmitted(false)} variant="contained">{text.submit_another_community}</Button>
            </Grid>
        </Grid>
        : (
        <form onSubmit={sendFormData}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Typography variant="h6" gutterBottom>
                    {text.Community_details}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="communityTitle"
                            name="communityTitle"
                            label="Community Name"
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="communityImage"
                            name="communityImage"
                            label="Community Image Url"
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateTimePicker
                            required
                            label="Start Time"
                            value={startDate}
                            onChange={changeStart}
                            renderInput={(params) => <TextField id="communityStart" name="communityStart" {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateTimePicker
                            required
                            id="communityEnd"
                            name="communityEnd"
                            label="End Time"
                            value={endDate}
                            onChange={changeEnd}
                            renderInput={(params) => <TextField id="communityStart" name="communityStart" {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="communityDetails"
                            name="communityDetails"
                            label="Community Details"
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}  justifyContent="flex-end">
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {text.submit_community}
                        </Button>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </form>
    );
}

const mapStateToProps = state => ({

});

const ActionCreators = Object.assign(
    {},
    { setCommunities },
    {setCommunityInDB}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityForm);