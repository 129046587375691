import {SET_USER_DATA, SET_USER_FAVORITES} from "../../constants";

const initialState =  {
    personData: {},
    userFavorites: {}
};

const PersonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                personData: action.payload
            }
        case SET_USER_FAVORITES:
            return {
                ...state,
                userFavorites: action.payload
            }
        default:
            return state
    }
}

export default PersonReducer;
