import React from "react";
import {auth} from "../config/Firebase";
import {Button} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import text from "../assests/text_en.json";

const signOutFunc = () => auth.signOut()

const SignOut = (props) => {
    const {style} = props;
    return (
        <>
            {auth.currentUser && (
            <Button onClick={signOutFunc} style={style}><ExitToAppIcon/>{text.sign_out}</Button>)}
        </>

    )
}

export default SignOut;