import { combineReducers } from 'redux';
import calendarReducer from './CalendarReducer';
import applicationReducer from './ApplicationReducer';
import eventsReducer from './EventsReducer';
import communityReducer from './CommunityReducer';
import personReducer from './PersonReducer';

const inTheOffice = combineReducers({
    calendarReducer,
    applicationReducer,
    eventsReducer,
    communityReducer,
    personReducer
})

export default inTheOffice
