import React from 'react';
import {Grid} from "@material-ui/core";
import MediaCard from "./MediaCard";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as dateFns from "date-fns";
import text from "../assests/text_en.json";

const Events = (props) => {
    const {events} = props;

    const eventJsx = (
        <Grid item xs={6}>
        <h2>{text.events}</h2>
        <Grid container spacing={3}>
            {events.map((e) => {
                const sDate = new Date(e.startdate);
                const eDate = new Date(e.enddate);
                let eDateRange = sDate.toDateString();

                if(sDate.getDate() !== eDate.getDate()){
                    eDateRange = `${dateFns.format(sDate,'MM/dd/yyyy')}-${dateFns.format(eDate,'MM/dd/yyyy')}`
                }


                return (
                    <Grid item lg={6} md={6} xs={12}>
                        <MediaCard img={e.photoURL} desc={e.description} title={e.name} dateRange={eDateRange} />
                    </Grid>)
            })}
        </Grid>
    </Grid>)

    return  events.length === 0 ?  null : eventJsx
};

const mapStateToProps = (state) => ({
    events: state.eventsReducer.eventData
})

const ActionCreators = Object.assign(
    {},
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);