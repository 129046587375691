import React from "react";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {Avatar} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import EventIcon from '@mui/icons-material/Event';
import Grid from "@mui/material/Grid";
import GroupsIcon from '@mui/icons-material/Groups';

const DayData = (props) => {
    const {dateToMap, dataToMap, events, communities} = props
    const monthCheck = dateToMap.getMonth().toString();

    function hasInitials(dateInfo) {
        return dateInfo?.person?.firstName && dateInfo?.person?.lastName && dateInfo?.person?.firstName.split("") && dateInfo?.person?.lastName.split("");
    }


    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
                <AvatarGroup max={4}>
                    {
                        dataToMap?.map((dateInfo) => {
                            if((new Date(dateInfo.date).getMonth()).toString() === monthCheck) {
                                return (
                                    <Avatar alt={`${dateInfo?.person?.firstName} ${dateInfo?.person?.lastName}`} src={dateInfo?.person?.photoURL}>
                                        { hasInitials(dateInfo) ?
                                            dateInfo?.person?.firstName.split("")[0] + dateInfo?.person?.lastName.split("")[0] : null
                                        }
                                    </Avatar>
                                )
                            }
                            return null;
                        })
                    }
                </AvatarGroup>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={6} >
                    {events && dateToMap && events[dateToMap.getDate()] && `${events[dateToMap.getDate()]}` === monthCheck ? <EventIcon /> : null}
                </Grid>
                <Grid item xs={6} >
                    {communities && dateToMap && communities[dateToMap.getDate()] && `${communities[dateToMap.getDate()]}` === monthCheck ? <GroupsIcon /> : null}
                </Grid>
            </Grid>
        </Grid>

    )

}

const mapStateToProps = (state) => ({
    events: state.eventsReducer.eventDays,
    communities: state.communityReducer.communityMap
})

const ActionCreators = Object.assign(
    {},
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DayData);