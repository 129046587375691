// useFetchData.js
import { useEffect } from "react";

const useFetchData = (actions, selectedDate, user) => {
  useEffect(() => {
    if (user) {
      actions.getCalDataForMonth(selectedDate);
      actions.getEventsForMonth(selectedDate);
      actions.getCommunitiesForMonth(selectedDate);
      actions.getUserDataInDB(user.uid);
    }
  }, [actions, selectedDate, user]);
};

export default useFetchData;
