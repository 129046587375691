import { Schedule } from "./rschedule";


export default function getDates(frequency, daysOfWeek, start, end){
    return new Schedule({
        rrules: [
          // This rule translates to:
          //  - "starting on January 7th, 2010,
          //    and ending on February 15th, 2011,
          //    every Sunday and the 3rd Monday
          //    in February and June."
          {
            frequency: frequency,
            byDayOfWeek: daysOfWeek,
            // reminder, javascript dates are 0 indexed
            start: new Date(start),
            end: new Date(end)
          }
        ]
      })
    }
    
//console.log("JSON schedule", schedule.toJSON());

// const scheduleDates = schedule
//   .occurrences()
//   .toArray()
//   .map(({ date }) => date.toString());


