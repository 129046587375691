// ListItemLinkComponent.js
import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

function ListItemLink({ icon, primary, to, onClick }) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick ? onClick : null}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default ListItemLink;
