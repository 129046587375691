import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AdminTable from "../components/AdminTable";


const AdminPage = () => {

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <AdminTable/>
            </Paper>
        </Container>
    );
}

const mapStateToProps = state => ({
    selectedDate: state.calendarReducer.selectedDate
});

const ActionCreators = Object.assign(
    {},
    {}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);