import React, {useState} from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../config/Firebase";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleDialog } from "../../../redux/actions/ApplicationActions";
import { setAttendanceDate, setSelectedDate } from "../../../redux/actions/CalendarActions";
import MediaCard from "../../MediaCard";
import PeopleList from "./PeopleList";
import text from "../../../assests/text_en.json";

function DayInfoDialog(props) {
  const {
    selectedDate,
    dialogStatus,
    calData,
    events,
    eventDays,
    communities,
    communityDays,
    toggleDialog,
    setAttendanceDate,
  } = props;

  const selectedDateData = calData[selectedDate.getDate()];
  const [user] = useAuthState(auth);

  const handleClose = () => {
    toggleDialog(!dialogStatus);
  };

  const [forceUpdate, setForceUpdate] = useState(false);

  const handleForceUpdate = () => {
      setForceUpdate(!forceUpdate);
  };

  const onDialogConfirm = (e) => {
    handleClose();
    setAttendanceDate(selectedDate, user?.uid);
  };

  console.log(selectedDateData)

  const doesEventExist = () =>
    eventDays && selectedDate && eventDays[selectedDate.getDate()] !== undefined;

  const doesCommunityExist = () =>
    communities && selectedDate && communityDays[selectedDate.getDate()] !== undefined;

  const RenderEvents = ({ events }) => (
    <Grid container spacing={3}>
      {events.map((e) => {
        if (
          new Date(e.enddate).getDate() >= selectedDate.getDate() &&
          new Date(e.startdate).getDate() <= selectedDate.getDate()
        ) {
          return (
            <Grid item md={doesCommunityExist() ? 6 : 3} xs={12}>
              <MediaCard img={e.photoURL} desc={e.description} title={e.name} />
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );

  const RenderCommunities = ({ communities }) => (
    <Grid container spacing={3}>
      {communities.map((e) => {
        if (
          new Date(e.enddate).getDate() >= selectedDate.getDate() &&
          new Date(e.startdate).getDate() <= selectedDate.getDate()
        ) {
          return (
            <Grid item md={doesEventExist() ? 6 : 3} xs={12}>
              <MediaCard img={e.photoURL} desc={e.description} title={e.name} />
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );

  return (
    <Dialog fullWidth={"xl"} maxWidth={"xl"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialogStatus}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {text.what_is_going_on_modal_header}
        {handleClose ? (
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              display: "block",
              float: "right",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        {selectedDateData ? (
            <>
              <Typography variant="h6" gutterBottom>
                {text.people_coming_modal_header}
              </Typography>
              <PeopleList peopleInfo={selectedDateData} selectedDate={selectedDate}  forceUpdate={forceUpdate} onStarClick={handleForceUpdate}  />
            </>
          ) : (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {text.be_the_first_to_join}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button autoFocus onClick={onDialogConfirm} size="large" color="success">
                  {text.modal_button_text}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={doesCommunityExist() ? 6 : 12}>
              {doesEventExist() ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    {text.events_modal_header}
                  </Typography>
                  <RenderEvents events={events} />
                </>
              ) : null}
            </Grid>
            <Grid item xs={doesEventExist() ? 6 : 12}>
              {doesCommunityExist() ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    {text.community_modal_header}
                  </Typography>
                  <RenderCommunities communities={communities} />
                </>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        {selectedDateData ? (
          <DialogActions>
            <Button autoFocus onClick={onDialogConfirm} color="primary">
              {text.modal_button_text}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
  
  DayInfoDialog.propTypes = {
    selectedDate: PropTypes.instanceOf(Date),
    dialogStatus: PropTypes.bool,
    calData: PropTypes.object,
    events: PropTypes.array,
    eventDays: PropTypes.object,
    communities: PropTypes.array,
    communityDays: PropTypes.object,
    toggleDialog: PropTypes.func.isRequired,
    setAttendanceDate: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    calData: state.calendarReducer.calData,
    selectedDate: state.calendarReducer.selectedDate,
    dialogStatus: state.applicationReducer.showDialog,
    events: state.eventsReducer.eventData,
    eventDays: state.eventsReducer.eventDays,
    communities: state.communityReducer.communityData,
    communityDays: state.communityReducer.communityMap,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    toggleDialog: bindActionCreators(toggleDialog, dispatch),
    setAttendanceDate: bindActionCreators(setAttendanceDate, dispatch),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(DayInfoDialog);
  