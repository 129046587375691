import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {useMemo} from "react";
import {Star} from "@material-ui/icons";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../config/Firebase";
import {removeAttendanceDate} from "../redux/actions/CalendarActions";
import {toggleDialog} from "../redux/actions/ApplicationActions";
import {removeUserFavoritesInDB, setUserFavoritesInDB} from "../redux/actions/PersonActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const FavoritesList = (props) => {
    const [checked, setChecked] = React.useState([1]);
    const {favorites} = useMemo(() => props, [props])
    const [user] = useAuthState(auth);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    return (
        <List  sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {favorites?.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                const person = value.person
                return (
                    <ListItem
                        key={person?.uid}
                        secondaryAction={
                            <Star/>
                        }
                        disablePadding
                        onClick={() => {
                            props.actions.removeUserFavoritesInDB(
                                {
                                    "personId": user?.uid,
                                    "favoritedPersonId": person?.uid
                                }
                            )
                        }}
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`${person?.firstName} ${person?.lastName}`}
                                    src={person.photoURL}
                                />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={`${person?.firstName} ${person?.lastName}`} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}

const mapStateToProps = (state) => ({
    favorites : state.personReducer.personData.favorites
})

const ActionCreators = Object.assign(
    {},
    {removeAttendanceDate},
    { toggleDialog },
    {removeUserFavoritesInDB}

);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesList);